<template>
  <div id="content">
    <div class="w-full mt-4">
      <label class="vs-input--label">{{ $t("address.country") }}</label>
      <v-select
        v-model="data_local.country"
        :clearable="false"
        :options="countries"
        v-validate="'required'"
        name="country"
        :searchable="true"
        :reduce="x => x.name"
        label="name"
        :data-vv-as="$t('address.country')"
      >
        <span slot="no-options">{{ $t("navbar.noResults") }}</span>
      </v-select>
      
      <span class="text-danger text-sm" v-show="errors.has('country')">{{
        errors.first("country")
      }}</span>
    </div>

    <div class="w-full mt-4">
      <label class="vs-input--label">{{ $t("address.state") }}</label>
      <v-select
        v-model="data_local.state"
        :clearable="false"
        :options="states"
        v-validate="'required'"
        name="state"
        :searchable="true"
        :reduce="x => x.name"
        label="name"
        :data-vv-as="$t('address.state')"
        :disabled="loadingStates || !states.length"
      >
        <span slot="no-options">{{ $t("navbar.noResults") }}</span>
      </v-select>
      
      <span class="text-danger text-sm" v-show="errors.has('state')">{{
        errors.first("state")
      }}</span>
    </div>
    
    <!--
    <vs-input
      class="w-full mt-4"
      :label="$t('address.city')"
      v-model="data_local.city"
      v-validate="'required'"
      name="city"
      :data-vv-as="$t('address.city')"
    />
    <span class="text-danger text-sm" v-show="errors.has('city')">{{
      errors.first("city")
    }}</span>
    -->

    <div class="w-full mt-4">
      <label class="vs-input--label">{{ $t("address.city") }}</label>
      <v-select
        v-model="data_local.city"
        :clearable="false"
        :options="cities"
        v-validate="'required'"
        name="city"
        :searchable="true"
        :reduce="x => x.name"
        label="name"
        :data-vv-as="$t('address.city')"
        :disabled="loadingCities || !cities.length"
      >
        <span slot="no-options">{{ $t("navbar.noResults") }}</span>
      </v-select>
      
      <span class="text-danger text-sm" v-show="errors.has('city')">{{
        errors.first("city")
      }}</span>
    </div>

    <vs-input
      class="w-full mt-4"
      :label="$t('address.zipcode')"
      v-model="data_local.zipcode"
      v-validate="'required'"
      :data-vv-as="$t('address.zipcode')"
      name="post_code"
      v-mask="['##.###-###']"
    />
    <span class="text-danger text-sm" v-show="errors.has('post_code')">{{
      errors.first("post_code")
    }}</span>

    <vs-input
      class="w-full mt-4"
      :label="$t('address.line')"
      v-model="data_local.street"
      v-validate="'required'"
      name="street"
      :data-vv-as="$t('address.line')"
    />
    <span class="text-danger text-sm" v-show="errors.has('street')">{{
      errors.first("street")
    }}</span>

  <!--
    <vs-input
      class="w-full mt-4"
      :label="$t('address.district')"
      v-model="data_local.district"
      name="district"
      :data-vv-as="$t('address.district')"
    />
    <span class="text-danger text-sm" v-show="errors.has('district')">{{
      errors.first("district")
    }}</span>
    -->
    
  </div>
  
</template>

<script>
import i18n from "@/i18n/i18n";
import vSelect from "vue-select";

export default {
  name: 'AddressComponent',
  components: {
    vSelect
  },
  data() {
    return {
      data_local: this.address,
      countries: [],
      states: [],
      cities: [],
      selectedCountry: null,
      selectedState: null,
      loadingStates: false,
      loadingCities: false
    };
  },
  props: ["address"],
  inject: [ 'parentValidator' ],
  created () {
    this.$validator = this.parentValidator
  },
  watch: {
    'data_local.country'(countryName) {
      this.selectedCountry = this.countries.find(country => country.name === countryName).id;
      this.listStates();
    },
    'data_local.state'(stateName) {
      this.selectedState = this.states.find(state => state.name === stateName).id;
      this.listCities();
    },
    data_local(value) {
      this.$emit('address_changed', value);
    }
  },
  methods: {
    listCountries() {
      this.$store.dispatch('httpRequest', {method: 'get', url:'location/countries'}).then((data) => {
          this.countries = data;

          if (this.data_local.country) {
            this.selectedCountry = this.countries.find(country => country.name === this.data_local.country).id;
            this.listStates();
          }

      }).catch(error => {
          this.$vs.notify({
              title: i18n.t('dialog.error'),
              text: error.message,
              color: 'danger'
          })
      });
    },
    listStates() {
      this.loadingStates = true;
      this.$store.dispatch('httpRequest', {method: 'get', url:`location/country/${this.selectedCountry}/states`}).then((data) => {
          this.states = data;
          this.loadingStates = false;

          const state = this.states.find(state => state.name === this.data_local.state);
          if (state && this.data_local.state) {
            this.selectedState = state.id;
            this.listCities();
          } else {
            this.selectedState = null;
          }

      }).catch(error => {
          this.$vs.notify({
              title: i18n.t('dialog.error'),
              text: error.message,
              color: 'danger'
          })
      });
    },
    listCities() {
      this.loadingCities = true;
      this.$store.dispatch('httpRequest', {method: 'get', url:`location/country/${this.selectedCountry}/state/${this.selectedState}/cities`}).then((data) => {
          this.cities = data;
          this.loadingCities = false;

          if (this.data_local.city && !this.cities.find(city => city.name === this.data_local.city)) {
            this.data_local.city = '';
          }

      }).catch(error => {
          this.$vs.notify({
              title: i18n.t('dialog.error'),
              text: error.message,
              color: 'danger'
          })
      });
    },
    isFormValid() {
      return !this.errors.any();
    }
  },
  mounted() {
    this.listCountries();
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
</style>
