<template>
  <div v-if="company && $store.state.AppActiveUser.type > 0">
    <feather-icon
      icon="XCircleIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
      @click="removeRecord"
    />
  </div>
</template>

<script>
import i18n from '../../../../i18n/i18n';

export default {
  data: () => ({
    company: null
  }),
  methods: {
    removeRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: i18n.t("company.actions.removeRelation"),
        text: `${i18n.t("company.messages.confirm.removeRelation")} ${this.company.name}?`,
        accept: this.delete,
        acceptText: i18n.t("actions.delete"),
        cancelText: i18n.t("actions.cancel")
      });
    },
    delete() {
      const isClient = this.company.type === 'client';
      const supporterCompanyId = isClient ? this.company.supporterCompanyId : this.company.id;
      const supportedCompanyId = isClient ? this.company.id : this.company.supporterCompanyId;

      this.$store
        .dispatch("httpRequest", {
          method: "delete",
          url: `company/support-company/${supportedCompanyId}`,
          data: {
            supporterCompanyId
          }
        })
        .then((data) => {

          if (data.token) {
            localStorage.setItem("accessToken", data.token);
          }
          
          this.showSuccess();
          this.$parent.$parent.loadData();
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    showSuccess() {
      this.$vs.notify({
        color: "success",
        title: i18n.t("dialog.success"),
        text: i18n.t("company.messages.success.relationRemoved")
      });
    }
  },
  mounted() {
    this.company = this.params.data;
  }
};
</script>
