<template lang="html">
    <div id="page-user-edit"  class="vx-col w-full vs-con-loading__container" v-if="company">

        <vx-card class="mb-5" >
          <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$router.push('/company').catch(() => {})" class="cursor-pointer mr-4" svg-classes="w-6 h-6"></feather-icon>
              <div>
                <h3 class="text-primary">{{ (company.name) ? company.name : ('[ ' + $t('common.name') + ' ]') }}</h3>
                <h6>{{ company.trade }}</h6>
              </div>
            </div>
        </vx-card>

        <vs-tabs v-model="activeTab" class="tabs-shadow-none"
          :position="'left'" id="company-tabs" >
          <vs-tab :label="$t('common.register')" icon-pack="feather" icon="icon-clipboard">
            <div class="tab-general md:ml-4 mt-0 ml-0">
              <company-data :data_local="company" @updateData="updateData"/>
            </div>
          </vs-tab>

          <vs-tab :label="$t('company.contracts')" icon-pack="feather" icon="icon-file-text" :disabled="!company.id" v-if="$store.state.AppActiveUser.type === 2">
            <div class="tab-general md:ml-4 mt-0 ml-0">
              <company-contract :data_local="company" :files="files" @updateList="listFiles"></company-contract>
            </div>
          </vs-tab>

          <vs-tab :label="$t('company.relationship')" icon-pack="feather" icon="icon-repeat" :disabled="!company.id">
            <div class="tab-general md:ml-4 mt-0 ml-0">
              <company-relationship :data_local="company"></company-relationship>
            </div>
          </vs-tab>
        </vs-tabs>
    </div>
</template>

<script>
import CompanyContract from './CompanyContract.vue'
import CompanyData from './CompanyData.vue'
import CompanyRelationship from './CompanyRelationship.vue'

import i18n from '@/i18n/i18n'

export default {
  components: {
      CompanyContract,
      CompanyData,
      CompanyRelationship
  },
  data:()=>({
      company: null,
      activeTab: 0,
      files: []
  }),
  computed:{
    isSmallerScreen() {
        return this.$store.state.windowWidth < 768
    }
  },
  beforeMount() {
    if (!this.$route.params.new && !this.$route.params.company){
        this.$router.push("/company")
    }
  },
  created() {
      this.company = this.$route.params.company;
  },
  mounted: function () {
    this.$nextTick(function () {
      this.listFiles();
    })
  },
  methods: {
    updateData(data){
      this.company.id = data.id;
      this.activeTab = 1;
    },
    listFiles() {
  
      if (!this.company) return;

      var self = this
            
      this.$vs.loading({
          container: '#page-user-edit',
          scale: 0.5
      });

      this.$store.dispatch('httpRequest', {method: 'get', url:('contract/bucket/' + this.company.id)}).then((data) => {
          self.$vs.loading.close('#page-user-edit > .con-vs-loading')
          this.files = data.files;
      }).catch(error => {
          self.$vs.loading.close('#page-user-edit > .con-vs-loading')
          this.$vs.notify({
              title: i18n.t('dialog.error'),
              text: error.message,
              color: 'danger'
          })
      });
    },
  }
}

</script>

<style lang="scss">
#company-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>