<template>
    <div v-if="contract">
      <contract-sidebar :isSidebarActive="addNewDataSidebar" 
            @closeSidebar="toggleDataSidebar"
            @updateRow="updateRowData"
            @updateListData="updateListData"
            :data="sidebarData"
            :files="files" />

      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon v-if="contract.active" icon="XCircleIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmActivate(false)" />
      <feather-icon v-else icon="CheckCircleIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer" @click="confirmActivate(true)" />
    </div>
    
</template>

<script>
import i18n from '../../../../i18n/i18n'

import ContractSidebar from "../ContractSidebar.vue"

export default {
    components: {
        ContractSidebar
    },
    data:()=>({
        contract: null,
        files: [],
        
         // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},
    }),
    methods: {
      editRecord() {
        this.sidebarData = {};
        this.sidebarData = { ...this.params.data };
        this.toggleDataSidebar(true);
      },
      confirmActivate(active) {
        this.$vs.dialog({
          type: 'confirm',
          color: (!active ? 'danger' : 'success'),
          title: (!active ? i18n.t('contract.actions.deActivate') : i18n.t('contract.actions.activate')),
          text: `${!active ? i18n.t('contract.messages.confirm.deActivate') : i18n.t('contract.messages.confirm.activate')} "${this.params.data.contract_code}"?` ,
          accept: (!active ? this.deActivate : this.activate),
          acceptText: (!active ? i18n.t('actions.deActivate') : i18n.t('actions.activate')),
          cancelText: i18n.t('actions.cancel')
        })
      },
      activate(){
        this.activateRecord(true);
      },
      deActivate(){
        this.activateRecord(false);
      },
      activateRecord(active) {
        var record = { id: this.params.data.id, active: active };
        
        this.$store.dispatch('httpRequest', {
          method: 'post', 
          url:'contract/edit',
          data: record
        }).then(() => {
            this.showSuccess(active);
            this.$parent.$parent.loadData();
        }).catch(error => {
            this.$vs.notify({
                title: i18n.t('dialog.error'),
                text: error.message,
                color: 'danger'
            })
        });

      },
      showSuccess(active) {
        this.$vs.notify({
          color: 'success',
          title: i18n.t('dialog.success'),
          text: (!active ? i18n.t('contract.messages.success.deActivate') : i18n.t('contract.messages.success.activate'))
        })
      },
      toggleDataSidebar(val=false) {
        this.addNewDataSidebar = val
      },
      updateRowData(){
        this.$parent.$parent.loadData();
      },
      updateListData(){
        this.$parent.$parent.updateListData();
      }
    },
    mounted() {
      this.contract = this.params.data;
      this.sidebarData = { ...this.params.data };

      this.files = this.$parent.$parent.files;
    }
}
</script>
