<template lang="html">
  <div id="page-list">
    <div id="list-content" class="vx-card p-6 vs-con-loading__container">
      <div class="flex flex-wrap justify-between items-center">
        <div
          v-if="$store.state.AppActiveUser.type > 0"
          class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
          @click="activePrompt = true"
        >
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-primary">{{
            $t("actions.add")
          }}</span>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :animateRows="true"
      >
      </ag-grid-vue>
      <vs-pagination :total="totalPages" v-model="currentPage" />
    </div>

    <vs-prompt
      class="add-voucher-dialog"
      :title="$t('company.actions.addRelation')"
      :buttonAccept="'false'"
      :buttonCancel="'false'"
      :type="'alert'"
      @cancel="val = ''"
      :active.sync="activePrompt"
    >
      <div>
        <div>
          <span>{{ $t("company.entity") }}</span>
            <v-select
              v-model="supportedCompanyId"
              :clearable="false"
              :options="entityOptions"
              v-validate="'required'"
              name="entity"
              :searchable="false"
              :reduce="x => x.id"
              label="name"
              :data-vv-as="$t('company.entity')"
            />

            <span class="text-danger text-sm" v-show="errors.has('entity')">{{
              errors.first("entity")
            }}</span>
        </div>

        <div class="flex flex-wrap p-2" slot="footer">
          <vs-button
            id="button-with-loading"
            class="ml-auto mt-2 vs-con-loading__container"
            @click.native="addRelation"
            >{{ $t("actions.save") }}</vs-button
          >
          <vs-button
            type="flat"
            color="dark"
            class="ml-4 mt-2"
            @click.native="activePrompt = false"
            >{{ $t("actions.cancel") }}</vs-button
          >
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import vSelect from "vue-select";
//import moment from "moment";

// Cell Renderer
import RelationshipCellActions from "./cell-renderer/RelationshipCellActions.vue"


export default {
  components: {
    AgGridVue,
    vSelect,
    RelationshipCellActions
  },
  props: ["data_local"],
  data: () => ({
    data: null,
    dataRaw: null,
    status: true,
    activePrompt: false,
    entityOptions: [],
    supportedCompanyId: null,
    // AgGrid
    gridOptions: {
      localeTextFunc: function(key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true,
      context: {
        componentParent: this
      }
    },
    gridApi: null,
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMenu: true
    },
    columnDefs: [
      {
        headerName: i18n.t("common.id"),
        width: 70,
        field: "id",
        filter: true
      },
      {
        headerName: i18n.t("common.name"),
        field: "name",
        filter: true
      },
      {
        headerName: i18n.t("common.type"),
        field: "type",
        filter: true
      },
      {
        headerName: i18n.t("actions.title"),
        width: 100,
        field: "actions",
        suppressSizeToFit: true,
        cellRendererFramework: "RelationshipCellActions",
      },
    ]
  }),
  watch: {
   
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    addRelation() {

      this.$store
        .dispatch("httpRequest", {
          method: "post",
          url: "company/support-company",
          data: {
            supporterCompanyId: this.data_local.id,
            supportedCompanyId: this.supportedCompanyId
          }
        })
        .then(data => {
          if (data.error) {
            this.$vs.notify({
              title: i18n.t("dialog.error"),
              text: data.error,
              color: "danger"
            });
          } else {
            this.$vs.notify({
              title: i18n.t("dialog.success"),
              color: 'success',
              text: i18n.t(
                "company.messages.success.entityAdded"
              )
            });

            if (data.token) {
              localStorage.setItem("accessToken", data.token);
            }
            this.activePrompt = false;
            this.loadData();
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    addItem() {
      
    },

    loadCompanies() {
      var self = this;

      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then((data) => {
          const linkedCompaniesIds = self.data.map(x => x.id);
          self.entityOptions = data.filter(
            x => x.active && x.id != self.data_local.id && !linkedCompaniesIds.includes(x.id)
          );
        });
    },
    
    loadData() {
      var self = this;

      this.$vs.loading({
        container: "#list-content",
        scale: 0.5
      });

      return this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `company/${this.data_local.id}/linked-companies`
        })
        .then(data => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          self.data = data;

          self.data.forEach(function (linkedCompany) {
            linkedCompany.supporterCompanyId = self.data_local.id;
          });

          self.loadCompanies();
        })
        .catch(error => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.api.sizeColumnsToFit();
    this.loadData();
  }
};
</script>
<style lang="scss">
.add-voucher-dialog .vs-dialog footer {
  display: none !important;
}
.not-data-table {
  display: none !important;
}
</style>