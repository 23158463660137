
<template lang="html">
    <div id="page-list">
        <div id="list-content" class="vx-card p-6 vs-con-loading__container">

            <contract-sidebar :isSidebarActive="addNewDataSidebar" 
                @closeSidebar="toggleDataSidebar" 
                :data="sidebarData" 
                @updateRow="loadData" 
            />

            <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-switch color="success" v-model="status">
                    <span slot="on">{{$t('status.active')}}</span>
                    <span slot="off">{{$t('status.inactive')}}</span>
                </vs-switch>
            </vs-row>

            <div class="flex flex-wrap justify-between items-center">
                <div v-if="$store.state.AppActiveUser.type > 0"
                    class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addItem">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">{{ $t('actions.add') }}</span>
                </div>
            </div>

            <ag-grid-vue
                ref="agGridTable"
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 ag-grid-table"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="data"
                rowSelection="multiple"
                colResizeDefault="shift"
                :floatingFilter="true"
                :pagination="true"
                :paginationPageSize="paginationPageSize"
                :suppressPaginationPanel="true"
                :animateRows="true">
            </ag-grid-vue>
            <vs-pagination
                :total="totalPages"
                v-model="currentPage" />
        </div>
    </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import i18n from '@/i18n/i18n'
import { getAgGridTranslation } from '@/utils/utils'
import vSelect from 'vue-select'
import moment from 'moment'

// Cell Renderer
import ContractCellActions from "./cell-renderer/ContractCellActions.vue"
import ContractSidebar from "./ContractSidebar.vue"


function dateFormatter(params){
    return moment(params.value).format('DD/MM/YYYY');
}

export default {
    components: {
        AgGridVue,
        vSelect,
        ContractCellActions,
        ContractSidebar
    },
    props: ["data_local", "files"],
    data:()=>({
        data: null,
        dataRaw: null,
        status: true,

        // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},

        // AgGrid
        gridOptions: {
           localeTextFunc: function(key, defaultValue) {
                return getAgGridTranslation(key, defaultValue);
            },
            suppressCellSelection: true,
            context: {
                componentParent: this
            }
        },
        gridApi: null,
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressMenu: true
        },
        columnDefs: [
           {
                headerName: i18n.t('common.id'),
                field: 'id',
                filter: true,
            },
            {
                headerName: i18n.t('contract.code'),
                field: 'contract_code',
                filter: true,
            },
            {
                headerName: i18n.t('contract.expiration'),
                field: 'expire_date',
                sort: 'asc',
                valueFormatter: dateFormatter,
                filter: true,
            },
            {
                headerName: i18n.t('contract.plan'),
                field: 'subscription',
                filter: true,
            },
            {
                headerName: i18n.t('actions.title'),
                width: 100,
                pinned: 'right',
                cellRendererFramework: 'ContractCellActions',
            },
        ],

    }),
    watch: {
        status: function (val) {
            this.data = this.dataRaw.filter(x => x.active == val);
        },
    },
    computed: {
        paginationPageSize() {
            if(this.gridApi) return this.gridApi.paginationGetPageSize()
            else return 10
        },
        totalPages() {
            if(this.gridApi) return this.gridApi.paginationGetTotalPages()
            else return 0
        },
        currentPage: {
            get() {
                if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
    },
    methods: {
        addItem() {
            this.sidebarData = { id_company : this.data_local.id };
            this.toggleDataSidebar(true);
        },
        loadData() {
           var self = this
            
            this.$vs.loading({
                container: '#list-content',
                scale: 0.5
            });

            this.$store.dispatch('httpRequest', {method: 'get', url:'contract/' + this.data_local.id }).then((data) => {
                self.$vs.loading.close('#list-content > .con-vs-loading')
                self.data = data.filter(x => x.active == this.status);
                self.dataRaw = data;
               
            }).catch(error => {
                self.$vs.loading.close('#list-content > .con-vs-loading')
                this.$vs.notify({
                    title: i18n.t('dialog.error'),
                    text: error.message,
                    color: 'danger'
                })
            });

        },
        toggleDataSidebar(val=false) {
            this.addNewDataSidebar = val;
        },
        updateListData(){
            this.$emit('updateList');
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api
        this.gridOptions.api.sizeColumnsToFit();
        this.loadData();
    },
}

</script>
