<template lang="html">
  <vx-card no-shadow>
    <div class="vx-row" v-if="dataLocal">
      <div class="vx-col w-1/2">
        <div class="flex items-end md:mt-0 mt-base">
          <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">{{ $t("common.info") }}</span>
        </div>

        <div>
          <vs-input
            class="w-full mt-4"
            label="Id"
            v-model="dataLocal.id"
            name="id"
            v-if="this.dataLocal.id"
            disabled
          />

          <div class="mt-4">
            <label class="vs-input--label">{{ $t("company.type") }}</label>
            <v-select
              v-model="dataLocal.type"
              :clearable="false"
              :options="typeOptions"
              v-validate="'required'"
              name="type"
              :searchable="true"
              :reduce="x => x.value"
              label="label"
              :data-vv-as="$t('company.type')"
            >
              <span slot="no-options">{{ $t("navbar.noResults") }}</span>
            </v-select>

            <span class="text-danger text-sm" v-show="errors.has('type')">{{
              errors.first("type")
            }}</span>
          </div>

          <vs-input
            class="w-full mt-4"
            :label="$t('company.name')"
            v-model="dataLocal.name"
            v-validate="'required'"
            name="name"
            :data-vv-as="$t('company.name')"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>

          <vs-input
            class="w-full mt-4"
            :label="$t('company.trade')"
            v-model="dataLocal.trade"
            v-validate="'required'"
            name="trade"
            :data-vv-as="$t('company.trade')"
          />
          <span class="text-danger text-sm" v-show="errors.has('trade')">{{
            errors.first("trade")
          }}</span>

          <div class="vx-row">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full mt-4"
                :label="$t('company.employer_id')"
                v-model="dataLocal.employer_id"
                v-validate="'required'"
                :data-vv-as="$t('company.employer_id')"
                v-mask="['###.###.##-##', '##.###.###/####-##', '##.###.###-X']"
                name="employer_id"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('employer_id')"
                >{{ errors.first("employer_id") }}</span
              >
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full mt-4"
                :label="$t('company.state_registration')"
                v-model="dataLocal.state_registration"
                name="state_registration"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('state_registration')"
                >{{ errors.first("state_registration") }}</span
              >
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full mt-4"
                :label="$t('common.phone') + ' 1'"
                v-model="dataLocal.phone1"
                v-validate="'required'"
                :data-vv-as="$t('common.phone') + ' 1'"
                name="phone1"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
              <span class="text-danger text-sm" v-show="errors.has('phone1')">{{
                errors.first("phone1")
              }}</span>
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full mt-4"
                :label="$t('common.phone') + ' 2'"
                v-model="dataLocal.phone2"
                name="phone2"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
              <span class="text-danger text-sm" v-show="errors.has('phone2')">{{
                errors.first("phone2")
              }}</span>
            </div>
          </div>

          <vs-input
            class="w-full mt-4"
            :label="$t('company.email')"
            v-model="dataLocal.email"
            v-validate="'email'"
            name="email"
            :data-vv-as="$t('company.email')"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>
      </div>

      <!-- Address Col -->
      <div class="vx-col w-1/2">
        <!-- Col Header -->
        <div class="flex items-end md:mt-0 mt-base">
          <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">{{
            $t("common.address")
          }}</span>
        </div>
        
        <address-component :address="dataLocal" v-on:address_changed="addressChanged"></address-component>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button
            id="button-with-loading"
            class="ml-auto mt-2 vs-con-loading__container"
            @click="saveChanges"
            :disabled="!validateForm || $store.state.AppActiveUser.type === 0"
            >{{ $t("actions.save") }}</vs-button
          >
          <vs-button
            class="ml-4 mt-2"
            type="flat"
            color="dark"
            @click.native="$router.push('/company').catch(() => {})"
            >{{ $t("actions.cancel") }}</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import i18n from "@/i18n/i18n";
import vSelect from "vue-select";
import AddressComponent from '../components/AddressComponent.vue'

export default {
  components: {
    vSelect,
    AddressComponent
  },
  data: () => ({
    typeOptions: [
      { label: i18n.t("common.company"), value: 0 },
      { label: i18n.t("common.astec"), value: 1 }
    ],
  }),
  provide () {
    return { parentValidator: this.$validator }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    dataLocal: {
      get(){
        return this.data_local
      },
      set(value){
        return value
      } 
    }
  },
  props: ["data_local"],
  methods: {
    addressChanged: function(address) {
			this.dataLocal = { ...this.dataLocal, ...address };
		},
    saveChanges() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;

          this.$vs.loading({
            container: "#button-with-loading",
            scale: 0.5
          });

          var isNew = this.$route.params.new;
          var url = isNew ? "company/create" : "company/edit";
          var message = isNew
            ? i18n.t("company.messages.success.create")
            : i18n.t("company.messages.success.edit");
          delete this.dataLocal.typeTranslated;

          this.$store
            .dispatch("httpRequest", {
              method: "post",
              url: url,
              data: this.dataLocal
            })
            .then(response => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              if (response.success) {
                self.dataLocal.id = isNew ? response.id_company : self.dataLocal.id;
                this.$emit("updateData", self.dataLocal);

                if (isNew) {
                  localStorage.setItem("accessToken", response.token);
                }

                this.$vs.notify({
                  title: i18n.t("dialog.success"),
                  text: message,
                  color: "success"
                });
              } else {
                this.$vs.notify({
                  title: i18n.t("dialog.error"),
                  text: response.message,
                  color: "danger"
                });
              }
            })
            .catch(error => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$vs.notify({
                title: i18n.t("dialog.error"),
                text: error.message,
                color: "danger"
              });
            });
        }
      });
    }
  }
};
</script>
