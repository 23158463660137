<template>
  <vs-prompt color="primary" class="add-new-data-dialog"
    @close="isSidebarActiveLocal = false"
    @accept="submitData"
    :buttonAccept="'false'"
    :buttonCancel="'false'"
    :type="'alert'"
    :title="(!data_local.id ? $t('actions.add') : $t('actions.edit')) + ' ' + $t('common.contract')" 
    :active.sync="isSidebarActiveLocal">
    <div id="content">
      <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6 vs-dialog-contract">
          <div class="vx-row">
            <div class="vx-col mt-4 sm:w-1/2 w-full">
              <vs-input :label="$t('contract.code')" v-model="data_local.contract_code" class="w-full"
                name="code" id="code" v-validate="'required'" :data-vv-as="$t('contract.code')" />
              <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
            </div>

            <div class="vx-col mt-4 sm:w-1/2 w-full">
                <label class="text-sm">{{$t('contract.expiration')}}</label>
                <datepicker v-model="data_local.expire_date" :language="languages[currentLanguage]" 
                  name="expiration" format="dd/MM/yyyy" :data-vv-as="$t('contract.expiration')"></datepicker>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col mt-4 sm:w-1/2 w-full">
                <vs-input :label="$t('contract.dueDate')" v-model="data_local.billing_day" class="w-full"
                name="dueDate" v-validate="'required'" :data-vv-as="$t('contract.dueDate')" />        
                <span class="text-danger text-sm"  v-show="errors.has('dueDate')">{{ errors.first('dueDate') }}</span>
            </div>

            <div class="vx-col mt-4 sm:w-1/2 w-full">
                <label class="vs-input--label">{{$t('contract.plan')}}</label>
                <v-select v-model="plan_local" :clearable="false" :options="planOptions"
                    name="plan" :data-vv-as="$t('contract.plan')" :searchable="false" />
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col mt-4 sm:w-1/2 w-full">
                <label class="vs-input--label">{{$t('contract.type')}}</label>
                <v-select v-model="type_local" :clearable="false" :options="typeOptions"
                    name="type" :data-vv-as="$t('contract.type')" :searchable="false" />
            </div>
          </div>

          <div class="vx-row mt-8">
            <div class="vx-col w-full">
              <div class="flex items-end md:mt-0 mt-base">
                  <span class="leading-none font-bold">{{$t('contract.values.title')}}</span>
              </div>
            </div>

            <div class="vx-col mt-4 sm:w-1/2 w-full">
              <vs-input :label="$t('contract.values.preventive')" v-model="data_local.preemptive_maintenance" class="w-full"
                name="preventive" v-validate="'required'" :data-vv-as="$t('contract.values.preventive')" />
              <span class="text-danger text-sm" v-show="errors.has('preventive')">{{ errors.first('preventive') }}</span>
            </div>

            <div class="vx-col mt-4 sm:w-1/2 w-full">
              <vs-input :label="$t('contract.values.corrective')" v-model="data_local.corrective_maintenance" class="w-full"
                name="corrective" v-validate="'required'" :data-vv-as="$t('contract.values.corrective')" />
              <span class="text-danger text-sm" v-show="errors.has('corrective')">{{ errors.first('corrective') }}</span>
            </div>

            <div class="vx-col mt-4 sm:w-1/2 w-full">
              <vs-input :label="$t('contract.values.unproductive')" v-model="data_local.unproductive_maintenance" class="w-full"
                name="unproductive" v-validate="'required'" :data-vv-as="$t('contract.values.unproductive')" />
              <span class="text-danger text-sm" v-show="errors.has('unproductive')">{{ errors.first('unproductive') }}</span>
            </div>
            
            <div class="vx-col mt-4 sm:w-1/2 w-full">
              <vs-input :label="$t('contract.values.detached')" v-model="data_local.separate_maintenance" class="w-full"
                name="detached" v-validate="'required'" :data-vv-as="$t('contract.values.detached')" />
              <span class="text-danger text-sm" v-show="errors.has('detached')">{{ errors.first('detached') }}</span>
            </div>
          </div>

          <div class="vx-row mt-8">
            <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  $t("machine.machines")
                }}</label>
                <v-select
                  v-model="data_local.machines"
                  multiple
                  :closeOnSelect="false"
                  :options="machineOptions"
                  :reduce="x => x.id"
                  label="serial_number"
                />
            </div>
          </div>

          <div class="vx-col w-full mt-10">
            <div class="flex items-end md:mt-0 mt-base">
                <span class="leading-none font-bold">{{$t('common.files')}}</span>
            </div>
          </div>
          <div class="vx-col w-full mt-4" v-if="data_local.id">
            <div class="flex items-end md:mt-0 mt-base">
                <div class="sm:w-1/2 mr-5">
                  <label class="vs-input--label">{{$t('contract.fileType')}}</label>
                  <v-select v-model="fileType" :clearable="false" :options="fileTypeOptions"
                      name="fileType" :searchable="false" :reduce="x => x.value" label="label" />
                </div>

                <vs-button color="primary" id="upload-button-with-loading" icon-pack="feather" icon="icon-upload"
                  class="vs-con-loading__container" @click.native="uploadFile" :disabled="!file" >Upload</vs-button>
                <input class="w-full ml-5" type="file" id="file" ref="file" accept=".doc,.docx,.pdf" v-on:change="handleFileChange()" />
            </div>
          </div>
          <div class="vx-row px-4">
            <vs-list v-if="data_local.id">
              
              <div v-if="main_file.filepath">
                <vs-list-header icon-pack="feather" icon="icon-file-text" :title="$t('contract.fileTypes.main')"></vs-list-header>
                <vs-list-item :title="main_file.filepath" :subtitle="main_file.uploadDate | moment('DD/MM/YYYY HH:mm:ss')">
                  <vs-button color="warning" icon-pack="feather" id="btnFileMain" class="vs-con-loading__container" 
                    icon="icon-download" @click="downloadFile(main_file.filepath, 'btnFileMain')"></vs-button>
                </vs-list-item>
              </div>

              <div v-if="warranty_file.filepath">
                <vs-list-header icon-pack="feather" icon="icon-shield" :title="$t('contract.fileTypes.warranty')"></vs-list-header>
                <vs-list-item :title="warranty_file.filepath" :subtitle="warranty_file.uploadDate | moment('DD/MM/YYYY HH:mm:ss')">
                  <vs-button color="warning" icon-pack="feather" id="btnFileWarranty" class="vs-con-loading__container" 
                    icon="icon-download" @click="downloadFile(warranty_file.filepath, 'btnFileWarranty')"></vs-button>
                </vs-list-item>
              </div>
              
              <div v-if="ammends_files.length > 0">
                <vs-list-header icon-pack="feather" icon="icon-file-plus" :title="$t('contract.fileTypes.ammends')"></vs-list-header>
                <vs-list-item v-for="(file, index) in ammends_files" :key="index" :title="file.filepath" :subtitle="file.uploadDate | moment('DD/MM/YYYY HH:mm:ss')">
                  <vs-button color="warning" icon-pack="feather" :id="'btn_' + index" class="vs-con-loading__container" 
                    icon="icon-download" @click="downloadFile(file.filepath, 'btn_' + index)"></vs-button>
                </vs-list-item>
              </div>

            </vs-list>
            <vs-alert class="mt-5" v-if="!data_local.id" color="warning" icon-pack="feather" icon="icon-info">
              <span>{{ $t('contract.messages.info.saveBeforeFiles') }}</span>
            </vs-alert>
          </div>
        </div>
      </VuePerfectScrollbar>
      <vs-divider class="mb-0" color="lightgray"></vs-divider>
      <div class="flex flex-wrap p-2" slot="footer">
        <vs-button  id="button-with-loading" class="ml-auto mt-2 vs-con-loading__container"  @click.native="submitData" :disabled="!isFormValid">{{$t('actions.save')}}</vs-button>
        <vs-button  type="flat" color="dark" class="ml-4 mt-2" @click.native="isSidebarActiveLocal = false">{{$t('actions.cancel')}}</vs-button>
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import moment from 'moment'
import vSelect from 'vue-select'
import i18n from '@/i18n/i18n'


export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
    files: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    isSidebarActiveLocal(){
      this.updateData();
    },
  },
  data() {
    return {
      data_local: {},
      data_raw: {},
      language: "en",
      languages: lang,
      filename: '',
      file: false,
      ammends_files: [],
      fileType: 0,
      main_file: {},
      warranty_file: {},
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
      planOptions: [
          { label: i18n.t('contract.plans.standard'),  value: 'Standard' },
          { label: i18n.t('contract.plans.premium'),  value: 'Premium' },
          { label: i18n.t('contract.plans.platinum'),  value: 'Platinum' },
      ],
      typeOptions: [
          { label: i18n.t('contract.types.fixed'),  value: 'Fixo' },
          { label: i18n.t('contract.types.captation'),  value: 'Captacao' },
      ],
      fileTypeOptions: [
          { label: i18n.t('contract.fileTypes.main'),  value: 0 },
          { label: i18n.t('contract.fileTypes.warranty'),  value: 1 },
          { label: i18n.t('contract.fileTypes.ammends'),  value: 2 },
      ],
      machineOptions: []
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar');
          this.$validator.reset();
        }
      }
    },
    plan_local: {
        get() {
            if (!this.data_local.subscription)
                return this.planOptions[0];
            else
                return { label: this.planOptions.filter(x => x.value == this.data_local.subscription)[0].label,  value: this.data_local.subscription  }
        },
        set(obj) {
            this.data_local.subscription = obj.value
        }
    },
    type_local: {
        get() {
            if (!this.data_local.pricing)
                return this.typeOptions[0];
            else
                return this.typeOptions.filter(x => x.value == this.data_local.pricing)[0];
        },
        set(obj) {
            this.data_local.pricing = obj.value
        }
    },
    isFormValid() {
      return !this.errors.any();
    },
    currentLanguage() {
      var current = localStorage.getItem('language');
      return (current == 'pt_BR') ? 'ptBR' : localStorage.getItem('language');
    },
  },
  methods: {
    initValues() {
      
    },
    loadMachines() {
      var self = this;

      this.$store
        .dispatch("httpRequest", { method: "get", url: "machine/all" })
        .then(data => {
          self.machineOptions = data.filter(x => x.active == true);
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    handleFileChange(){
      this.file = this.$refs.file.files.length > 0;
    },
    uploadFile() {
      var self = this;

      this.$vs.loading({
          container: '#upload-button-with-loading',
          scale: 0.5
      });

      let dataForm = new FormData();
      
      for (let file of this.$refs.file.files) {
        dataForm.append(`file`, file);
      }

      this.$store.dispatch('uploadFileToServer', 
          {
            url: 'contract/upload/' + self.data_local.id_company, 
            data: dataForm
          })
        .then((response) => {
          self.$vs.loading.close('#upload-button-with-loading > .con-vs-loading');

          switch(self.fileType){
            case 0:
              self.main_file = { filepath: response.file, uploadDate: moment() };
              break;
            case 1:
              self.warranty_file = { filepath: response.file, uploadDate: moment() };
              break;
            case 2:
              self.ammends_files.push({ filepath: response.file, uploadDate: moment() });
          }

          this.$vs.notify({
              title: i18n.t('dialog.success'),
              text: i18n.t('contract.messages.success.fileUploaded'),
              color: 'success'
          });

      }).catch(error => {
          self.$vs.loading.close('#upload-button-with-loading > .con-vs-loading');
          this.$vs.notify({
              title: i18n.t('dialog.error'),
              text: error.message,
              color: 'danger'
          });
      });

    },
    submitData() {
      this.$validator.validateAll().then(result => {
          if(result) {
              var self = this;

              this.$vs.loading({
                  container: '#button-with-loading',
                  scale: 0.5
              });

              var payload = JSON.parse(JSON.stringify(this.data_local));
              payload.expire_date = moment(this.data_local.expire_date).format('YYYY-MM-DD');
              payload.main_file = this.main_file.filepath;
              payload.warranty_file = this.warranty_file.filepath;

              payload.ammends_files = '';
              for (let ammendFile of this.ammends_files) {
                payload.ammends_files += ammendFile.filepath + ',';
              }
              if (this.ammends_files.length > 0){
                payload.ammends_files = payload.ammends_files.slice(0, -1);
              }

              var isNew = !this.data_local.id;
              var url = isNew ? 'contract/create' : 'contract/edit';
              var message = isNew ? i18n.t('contract.messages.success.create') : i18n.t('contract.messages.success.edit');

              this.$store.dispatch('httpRequest', {method: 'post', url: url, data: payload}).then(() => {

                  self.$vs.loading.close('#button-with-loading > .con-vs-loading');

                  this.$vs.notify({
                      title: i18n.t('dialog.success'),
                      text: message,
                      color: 'success'
                  })

                  self.data_raw = self.data_local;
                  
                  this.$emit('updateRow', self.data_local);
                  this.$emit('updateListData');
                  this.$emit('closeSidebar');
                  this.$validator.reset();
                  
              }).catch(error => {
                  self.$vs.loading.close('#button-with-loading > .con-vs-loading')
                  this.$vs.notify({
                      title: i18n.t('dialog.error'),
                      text: error.message,
                      color: 'danger'
                  })
                  this.$emit('closeSidebar');
                  this.$validator.reset();
              });

              
            }
        });
    },
    downloadFile (path, btnId){
      var self = this;

      this.$vs.loading({
          container: ('#' + btnId),
          scale: 0.5
      });

      this.$store.dispatch('httpRequest', {
          method: 'get', 
          url:('contract/file/' + path),
        }).then((response) => {
          self.$vs.loading.close('#' + btnId + ' > .con-vs-loading')

          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', path)
          document.body.appendChild(link)
          link.click()
        }).catch(error => {
            this.$vs.notify({
                title: i18n.t('dialog.error'),
                text: error.message,
                color: 'danger'
            })
        });
    },
    updateData(){
      this.data_local = (this.data_raw.id) ? JSON.parse( JSON.stringify( this.data_raw )) : this.data;
      this.data_local.expire_date = new Date(moment(this.data_local.expire_date));

      let mainFile = this.files.filter(x => x.filepath == this.data_local.main_file);
      this.main_file = mainFile.length > 0 ? mainFile[0] : {};
      let warrantyFile = this.files.filter(x => x.filepath == this.data_local.warranty_file);
      this.warranty_file = warrantyFile.length > 0 ? warrantyFile[0] : {};

      if (this.data_local.ammends_files){
        let ammendsFiles = this.data_local.ammends_files.split(',');
        for (let file of ammendsFiles) {
          let ammendFile = this.files.filter(x => x.filepath == file);
          if (ammendFile.length > 0){
            this.ammends_files.push(ammendFile);
          }
        }
      }
    }
  },
  mounted() {
    this.data_raw = this.data;
    this.loadMachines();
    this.updateData();
  },
  updated: function () {
    this.$nextTick(function () {
      if (this.isSidebarActiveLocal && this.data_local.id){
        this.files_local = [];
      }
    })
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    Datepicker
  },
}
</script>

<style lang="scss">

.add-new-data-dialog .vs-dialog {
  max-width: 800px !important;
}

.add-new-data-dialog .vs-dialog footer {
  display: none !important;
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 182px);
}
</style>
